import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';
import { DecisionToolModule } from './app/decision-tool.module';
import { ENVIRONMENT } from './environments/environment';

Sentry.init({
  dsn: ENVIRONMENT.sentryDsn,
  tunnel: '/tunnel/polaris',
  release: ENVIRONMENT.version,
  environment: ENVIRONMENT.type,
  ignoreErrors: ['ResizeObserver loop limit exceeded', 'ResizeObserver loop completed with undelivered notifications.'],
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
      beforeNavigate: context => {
        return {
          ...context,
          name: 'Page: ' + location.pathname,
        };
      },
    }),
  ],
  tracesSampleRate: 1.0,
});

if (ENVIRONMENT.production) {
  enableProdMode();
} else {
  Error.stackTraceLimit = Infinity;
}

platformBrowserDynamic()
  .bootstrapModule(DecisionToolModule)
  .catch(err => console.error(err));
